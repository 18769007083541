// Make the DIV element draggable:
//dragElement(document.getElementById("mydiv"));

function dragElement(elementID) {
  //console.log(elmnt);
  console.log('attach drag event');
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

  var elmnt = document.getElementById(elementID);

  if(elmnt) {
    const elements = elmnt.querySelectorAll('.window-title');
    Array.from(elements).forEach((element, index) => {
      // conditional logic here.. access element
      // console.log('x');
      element.onmousedown = dragMouseDown;
    });
  }


  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    if(e) {
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

      // console.log(e);
      // elmnt.style.top = "300px";


    }
 }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
